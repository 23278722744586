/* flags */
div#b2b_sitetranslate_main i.flags, 
div#b2b_sitetranslate_main div.flags {
  background-color: white;
  width:26px;
  min-width:26px;
  height:16px;
}

body > div.skiptranslate {
  visibility: hidden !important;
  height:0;
  overflow:hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

body {
  top: 0px !important;
}


/* google */
#goog-gt-tt + div,
#script_googletranslate + div,
.goog-te-banner-frame {
  display:none !important;
  left: -2000px;
}

#goog-gt-tt, .goog-te-balloon-frame,
.goog-te-banner-frame.skiptranslate,
.goog-te-gadget-icon,
.goog-logo-link{
  display: none !important;
}

.goog-te-gadget{
  color:transparent!important;
}

.goog-text-highlight { 
  background: none !important; 
  box-shadow: none !important;
}

select.goog-te-combo {
  display:inline-flex;
  width:0;
  border: none;
  border-radius: 6px;
  color: var(--primary);
  padding-top: 2px;
  padding-bottom:2px;
  padding-right:28px;
  font-size:12px;
  background-color:transparent;
}

select.goog-te-combo:hover,
select.goog-te-combo:focus {
  border:none !important;
  border-color: transparent;
}
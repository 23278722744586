div.mobile-overlay {
	display:none;
	height:0;
}

@media screen and (min-width:1025px) {
	div.mobile-side aside {
		top:6rem;
		max-height: calc(100vh - 6rem);
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: var(--primary) #f8f8f8;
	}

	header.extra-info + header.header + div.container:not(.logon) div.mobile-side aside {
		top:7.5rem;
		max-height: calc(100vh - 7.5rem);
	}

	div.mobile-side aside::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

@media screen and (max-width:1024px) {
	div.mobile-side {
		display:block;
		position: fixed;
		top: 0;
		bottom:0;
		left: -100%;
		z-index: 100002;
		height:100%;
		min-height:100%;
		background: white;
		overflow:hidden;
		transition: left 0.2s ease-in-out;
		transform: translate3d(0, 0, 0);
	}

	div.mobile-side aside {
		top:0;
		height:100%;
		overflow-y: auto;
	}

	div.mobile-overlay {
		display:inherit;
		position: fixed;
		width:100%;
		height:100%;
		top:0;
		left:0;
		bottom:0;
		right:0;
		background-color: rgba(0, 0, 0, 0);
		z-index:100001;
		transition: all 0.4s ease;
		visibility: hidden;
	}

	div.mobile-overlay > svg {
		position:absolute;
		top: calc(50% - 20px);
		right:50px;
		color:white;
	}

	.mobile-on {
		max-height:100%;
	}

	.mobile-on div.mobile-side {
		display:block;
		left:0%;
		visibility: visible;
	}

	.mobile-on div.mobile-overlay {
		display:block;
		background-color: rgba(0, 0, 0, .6);
		visibility: visible;
		backdrop-filter: blur(2px);
	}	
}

@supports (-webkit-hyphens:none)
{	
	.mobile-on div.mobile-overlay {
		backdrop-filter: none;
	}
}
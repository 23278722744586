
div.stripe.loading {
  background: white repeating-linear-gradient(45deg, white, white 10px, rgba(0, 150, 200, 0.1) 10px, rgba(0, 150, 200, 0.1) 20px);
  animation-name: diagonal_move;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background-size: 200% 200%;
}

@keyframes diagonal_move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200px 0;
  }
}

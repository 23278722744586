.cartselect-list-small {
  width: calc(100% - 42px);
}

.cartselect-list-tiny {
  width: calc(100%);
}

.cartselect-input {
  width: calc(100% - 40px);
}

.cartcopy-input {
  width: calc(100%);
}

#cart_popup + div {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) #f8f8f8;
}

#cart_popup + div::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}